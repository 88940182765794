import React, { useContext } from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import PartnerCarousel from "../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../components/SectionTitle"
import { Banner } from "../../components/Banner"
import styled from "styled-components"
import { myContext } from "../../ThemeContext"
import BusinessOffers from "../../components/business/BusinessOffers"
import SEO from "../../components/seo"

export default function FinanceOnly() {
  const data = useStaticQuery(graphql`
    query businessFinanaceOnly {
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_207885558.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const theme = useContext(myContext)
  const TextAlignCenter = styled.div`
    text-align: center;
  `
  const EmphasiseContent = styled.div`
    background-color: ${theme.grey};
    text-align: center;
    padding: 4rem 0;
    margin-bottom: 4rem;
    span {
      color: ${theme.complementBlue};
    }
  `

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Finance Only Business" />
      <BusinessOffers />
      <Container>
        <SectionTitle
          title="Finance Only Solutions"
          description={
            <TextAlignCenter>
              <p>
                You can utilise a Compulease™ computer rental plan to acquire
                any equipment for your business in addition to IT which will
                provide you with all the benefits of computer leasing.
              </p>
              <p>
                Compulease™ will also be happy to work with your existing
                suppliers, so you will receive all the benefits of Compulease™
                without upsetting your current supplier relationships.
              </p>
            </TextAlignCenter>
          }
        />
      </Container>
      <EmphasiseContent>
        <Container>
          <Row>
            <Col>
              <h2>
                <span>Run out of budget?</span> Making a capital budget extend
                to meet the exact requirements of a business can be challenging
                to say the least and it is not at all uncommon for a business to
                find it still has remaining and urgent needs once the budget has
                been spent.
              </h2>
            </Col>
          </Row>
        </Container>
      </EmphasiseContent>

      <Banner
        image={data.BannerImage.childImageSharp.fluid}
        title="Sale & leaseback"
        description="Compulease™ can help by buying back equipment from your business that you've already purchased and lease it back to you, to release valuable working capital to the business for immediate use in whichever area you need."
        link={{
          text: "Get in Touch",
          to: "/business/ContactUs/",
        }}
      />
      <Container>
        <PeaceOfMindGuarantee />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
